import * as React from 'react';
import { useRef } from 'react';

import { SEO } from '../components/SEO';
import { Hero } from '../components/Hero';
import { Header } from '../components/Header';
import { Social } from '../components/Social';
import { Mission } from '../components/Mission';
import { Contact } from '../components/Contact';
import { Products } from '../components/Products';
import { Footer } from '../components/Footer';

import '../init/toastInit';

const IndexPage = () => {
  const heroRef = useRef(null);
  const productsRef = useRef(null);
  const missionRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <>
      <SEO title="Home" />
      <Header refs={[heroRef, productsRef, missionRef, contactRef]} />
      <Social />
      <Hero reference={heroRef} prodRef={productsRef} />
      <Products reference={productsRef} />
      <Mission reference={missionRef} />
      <Contact reference={contactRef} />
      <Footer />
    </>
  );
};

export default IndexPage;
