import * as React from 'react';

import './Footer.scss';

import logo from '../../assets/images/wdf.svg';

export const Footer = () => (
  <footer className="footer">
    <p className="pr-3">Iconic © {new Date().getFullYear()}</p>
    <a
      className="pl-3"
      href="https://wdf.cz"
      target="_blank"
      rel="noreferrer"
    >
      <img src={logo} alt="WDF s.r.o." />
    </a>
  </footer>
);
