import * as React from 'react';
import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import Masonry from 'react-masonry-css';

import { Product } from './Product';
import { httpRequest } from '../../lib/request';
import './Products.scss';
import { Reveal, Tween } from 'react-gsap';

interface Product {
  id: number;
  company: string;
  description: string;
  imageDetail: string;
  imageList: string;
  name: string;
  url: string;
}

interface Props {
  reference: MutableRefObject<null>
}

export const Products = ({ reference }: Props) => {
  const BREAKPOINTS = {
    default: 4,
    1400: 3,
    1074: 2,
  };

  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = useCallback(async () => {
    try {
      const response = await httpRequest(
        'https://tatianakucharova.com/realisation/realisation-list/',
        'get',
        undefined,
      );
      setProducts(response.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div id="products" ref={reference}>
      <Tween
        to={{ opacity: 1 }}
        delay={2}
      >
        <section
          className="products container"
        >
          <div className="container">
            <h2>Produkty</h2>
          </div>
          <Masonry
            breakpointCols={BREAKPOINTS}
            className="products__grid"
            columnClassName="products__grid-column"
          >
            {
              products?.map(item => (
                <Product
                  key={item.id}
                  company={item.company}
                  description={item.description}
                  imageDetail={item.imageDetail}
                  imageList={item.imageList}
                  name={item.name}
                  url={item.url}
                />
              ))
            }
          </Masonry>
        </section>
      </Tween>
    </div>
  );
};
