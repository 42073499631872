export const httpRequest = <B = undefined>(
  url: string,
  method = 'get',
  body: FormData | undefined,
) => {
  return fetch(url, {
    method: method.toUpperCase(),
    body: method !== 'get' ? body : undefined,
  }).then(response => {
    if (response.status >= 200 && response.status < 300) {
      return response.json();
    }

    return response.json().then(err => {
      throw new Error(err);
    });
  }).catch(err => {
    throw new Error(err);
  });
};
