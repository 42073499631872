import * as React from 'react';
import { ReactNode, MouseEvent } from 'react';

import './Button.scss';

interface Props {
  children: ReactNode;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  type: 'submit' | 'button';
  className?: string;
  disabled?: boolean;
}

export const Button = ({
  children,
  onClick,
  type,
  className,
  disabled,
}: Props) => {
  return (
    <button
      type={type}
      className={[
        'btn',
        className,
      ].join(' ')}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
