import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

export const HeroImageMain = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "hero-image-01.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Img
      fluid={data.file.childImageSharp.fluid}
      alt="Hero Image"
    />
  );
};
