export enum EmailValidation {
  Valid,
  RegexFailed,
}

export const validateEmail = (email: string): EmailValidation => {
  if (!email.match(/[^@][^@]*@[^.]*\...*/)) {
    return EmailValidation.RegexFailed;
  }

  return EmailValidation.Valid;
};
