import * as React from 'react';
import { Tween } from 'react-gsap';

import './Social.scss';

export const Social = () => (
  <Tween
    from={{ x: '-150px' }}
    duration={1}
    delay={2.5}
  >
    <div className="social">
      <a
        className="btn btn--primary"
        href="https://www.instagram.com/tatianagbrzobohata/"
        target="_blank"
        rel="noreferrer"
      >
        Instagram
      </a>
      <a
        className="btn btn--primary"
        href="https://www.facebook.com/tatianagregor.official"
        target="_blank"
        rel="noreferrer"
      >
        Facebook
      </a>
    </div>
  </Tween>
);
