import * as React from 'react';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from 'react';
import Scrollspy from 'react-scrollspy';

import './Header.scss';

import logo from '../../assets/images/logo-2023.svg';
import { Social } from '../Social';

interface Props {
  refs: MutableRefObject<null>[];
}

export const Header = ({ refs }: Props) => {
  const MENU_LINKS = [
    {
      id: 1,
      text: 'Úvod',
      hash: 'home',
      scrollToEl: refs[0],
    },
    {
      id: 2,
      text: 'Produkty',
      hash: 'products',
      scrollToEl: refs[1],
    },
    {
      id: 3,
      text: 'O nás',
      hash: 'about',
      scrollToEl: refs[2],
    },
    {
      id: 4,
      text: 'Kontakty',
      hash: 'contact',
      scrollToEl: refs[3],
    },
  ];

  const [toggleHeader, setToggleHeader] = useState<boolean>(false);
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = useCallback(() => {
    const offset = window.pageYOffset;

    if (offset > 30) {
      setToggleHeader(true);
    } else {
      setToggleHeader(false);
    }
  }, []);

  const handleScrollIntoView = useCallback(element => {
    element.current.scrollIntoView();
  }, []);

  return (
    <header
      className={[
        toggleHeader ? 'header--scrolled' : '',
        showMobileMenu ? 'header--mobile' : '',
        'header',
        'container-fluid',
        'px-4',
      ].join(' ')}
    >
      <img
        className={[
          toggleHeader ? 'header__logo--scrolled' : 'header__logo',
          'pt-3',
        ].join(' ')}
        src={logo}
        alt="Iconic"
        onClick={() => handleScrollIntoView(refs[0])}
      />
      <nav className="pt-3">
        <ul>
          <Scrollspy
            items={['home', 'products', 'about', 'contact']}
            currentClassName='active'
            offset={-100}
          >
            {
              MENU_LINKS.map(item => (
                <li
                  key={item.id}
                  className={[
                    'header__link',
                  ].join(' ')}
                  onClick={() => handleScrollIntoView(item.scrollToEl)}
                >
                  {item.text}
                </li>
              ))
            }
          </Scrollspy>
        </ul>
      </nav>
      <a
        className={[
          toggleHeader ? 'btn--outline' : 'btn--light',
          'header__cta',
          'btn',
          'px-3',
        ].join(' ')}
        target="_blank"
        href="https://tatianakucharova.com/"
        rel="noreferrer"
      >
        tatianakucharova.com
      </a>
      <button
        className={[
          showMobileMenu ? 'is-active' : '',
          'hamburger',
          'hamburger--vortex',
        ].join(' ')}
        type="button"
        onClick={() => setShowMobileMenu(!showMobileMenu)}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
      <div className="header__collapsible">
        {
          MENU_LINKS.map(item => (
            <li
              key={item.id}
              className={[
                'header__link',
              ].join(' ')}
              onClick={() => {
                handleScrollIntoView(item.scrollToEl);
                setShowMobileMenu(false);
              }}
            >
              {item.text}
            </li>
          ))
        }
        <Social />
        <a
          className="btn--outline btn px-3"
          target="_blank"
          href="https://tatianakucharova.com/"
          rel="noreferrer"
        >
          tatianakucharova.com
        </a>
      </div>
    </header>
  );
};
