import * as React from 'react';
import { MutableRefObject, useCallback } from 'react';
import { Tween } from 'react-gsap';

import { HeroImageMain } from './HeroImageMain';
import { HeroImageSide } from './HeroImageSide';
import './Hero.scss';

import logo from '../../assets/images/logo-sm.svg';
import { Button } from '../Button';

interface Props {
  reference: MutableRefObject<null>
  prodRef: MutableRefObject<null>
}

export const Hero = ({ reference, prodRef }: Props) => {
  const handleScrollIntoView = useCallback(element => {
    element.current.scrollIntoView();
  }, []);

  return (
    <div ref={reference} id="home">
      <Tween
        from={{ opacity: 0 }}
        delay={1.5}
        duration={0.5}
      >
        <section className="hero">
          <div className="container">
            <Tween
              from={{ x: '-150px', opacity: 0 }}
              delay={2}
              duration={0.5}
            >
              <div className="hero__image-main">
                <HeroImageMain />
              </div>
            </Tween>
            <Tween
              from={{ x: '150px', opacity: 0 }}
              delay={2}
              duration={0.5}
            >
              <div className="hero__image-side">
                <HeroImageSide />
              </div>
            </Tween>
            <Tween
              from={{ y: '-150px', opacity: 0 }}
              delay={2}
              duration={0.5}
            >
              <figure className="hero__logo">
                <img src={logo} alt="Iconic" />
              </figure>
            </Tween>
            <Tween
              from={{ opacity: 0 }}
              duration={1}
              delay={2}
            >
              <div className="hero__heading">
                <h1>
                  Přátelé a milovníci kvalitních produktů.
                </h1>
                <p>
                  Mám nesmírnou radost představit Vám nový
                  osobní projekt z mojí “Iconic by TATIANA KUCHAROVA”
                  kolekce. Vytvořila jsem pro Vás ve spolupráci
                  s vybranými LOVE brands speciální kolekce těch
                  nejlepších produktů pro zpříjemnění Vášeho
                  každodenního dne. Ať je to vysoce kvalitní čokoláda
                  “<b>Chocolate Therapy by TATIANA KUCHAROVA</b>”, speciální
                  výběrová edice kávy “<b>Iconic Coffee by TATIANA KUCHAROVA</b>”
                  nebo luxusní svíčka “<b>Iconic Moments by TATIANA KUCHAROVA</b>”
                  vždy se jedná o můj osobní dotyk pro zpříjemnění
                  Vašich chvílí pohody.
                </p>
                <p>Vždy tam bude malý kousek mě samotné...</p>
                <p>😌❤️🙏🏻</p>
                <p className="mb-4">Vaše Taťána Kuchařová</p>
              </div>
              <div>
                <Button
                  className="hero__cta btn--outline btn--lg btn px-4"
                  type='button'
                  onClick={() => handleScrollIntoView(prodRef)}
                >
                  Katalog produktů
                </Button>
              </div>
            </Tween>
          </div>
        </section>
      </Tween>
    </div>
  );
};
