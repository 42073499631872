import * as React from 'react';
import { useState } from 'react';

import './Product.scss';

interface Props {
  company: string;
  description: string;
  imageDetail: string;
  imageList: string;
  name: string;
  url: string;
}

export const Product = ({
  company,
  description,
  imageDetail,
  imageList,
  name,
  url,
}: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <article
        className="product"
        onClick={() => setShowModal(true)}
      >
        <figure className="product__image">
          <img src={imageList} alt="Product" />
          <div className="product__icon">
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="inherit"
                d="M10.5985.005l-1-.01-.093 9.505h-9.506v1h9.495l-.093 9.494 1 .01.094-9.504h9.504v-1h-9.494z"
              />
            </svg>
          </div>
        </figure>
        <p className="product__brand">{company}</p>
        <h3 className="product__title">{name}</h3>
        <p className="product__description">{description}</p>
      </article>
      {
        showModal &&
        <dialog
          className="product__detail"
          open={showModal}
        >
          <figure>
            <img src={imageDetail} alt={name} />
          </figure>
          <aside>
            <button
              className="product__close"
              onClick={() => setShowModal(false)}
            >
              <svg
                width="16px"
                height="16px"
                viewBox="0 0 16 16"
              >
                <path d="M15.1406.9985L.8601 15.0009M.929.929l14.142 14.142" stroke="inherit" />
              </svg>
            </button>
            <p className="product__brand mb-4">{company}</p>
            <h2 className="product__title mb-4">{name}</h2>
            <p className="product__description mb-4">{description}</p>
            <a
              className="btn btn--outline btn--lg px-4"
              href={url}
              target="_blank"
              rel="noreferrer"
              onClick={() => setShowModal(false)}
            >
              Zakoupit
            </a>
          </aside>
        </dialog>
      }
      {
        showModal &&
          <div
            className="product__backdrop"
            onClick={() => setShowModal(false)}
          />
      }
    </>
  );
};
