import * as React from 'react';
import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import { Reveal, Tween } from 'react-gsap';

import { validateEmail, EmailValidation } from '../../lib/validators';
import { Button } from '../Button';
import { httpRequest } from '../../lib/request';
import './Contact.scss';

interface Props {
  reference: MutableRefObject<null>
}

export const Contact = ({ reference }: Props) => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [emailValid, setEmailValid] = useState<EmailValidation | null>(null);
  const [awaitResponse, setAwaitResponse] = useState<boolean>(false);

  const recaptchaRef = React.useRef(null);

  useEffect(() => {
    if (email !== '') {
      setEmailValid(validateEmail(email));
    }
  }, [email]);

  const handleSubmit = useCallback(async () => {
    const recaptchaEl = recaptchaRef.current as any;
    const formData = new FormData();
    setAwaitResponse(true);

    try {
      const token = await recaptchaEl.executeAsync();
      formData.append('g-recaptcha-response', token);
    } catch (e: any) {
      toast.error(e);
    }

    formData.append('contactFormName', name);
    formData.append('contactFormMail', email);
    formData.append('contactFormMessage', message);
    formData.append('contactFormLocale', 'cs');

    try {
      await httpRequest(
        'https://tatianakucharova.com/forms/contact-form-iconic/',
        'post',
        formData,
      );
      toast.success('Vaše zpráva byla odeslána!');
    } catch (e) {
      toast.error('Zprávu nelze odeslat!');
    } finally {
      setName('');
      setEmail('');
      setMessage('');
      setAwaitResponse(false);
    }
  }, [name, email, message]);

  return (

    <section ref={reference} id="contact" className="contact">
      <Reveal threshold={0}>
        <Tween from={{ opacity: 0, x: 200 }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h3 className="contact__heading">
                  Máte zájem<br /> o spolupráci?<br /> Kontaktujte nás.
                </h3>
                <div className="press-info mb-4">
                  Manažerka Iconic by TATIANA KUCHAROVA brands<br />
                  <h4 className="press-info__title">Michaela Šedo</h4>
                </div>
                <a href="mailto:info@iconicbytatianakucharova.com" className="contact__email">info@iconicbytatianakucharova.com</a>
                <div className="contact__info">
                  <p>Iconic s.r.o</p>
                  <p className="mx-4">IČO: 04140575</p>
                  <p>DIČ: CZ04140575</p>
                </div>
                <a
                  href="https://tatianakucharova.com"
                  className="contact__email"
                  target="_blank"
                  rel="noreferrer"
                >
                  tatianakucharova.com
                </a>
              </div>
              <div className="col-lg-6">
                <div className="contact__input">
                  <input
                    value={name}
                    type="text"
                    name="name"
                    required
                    aria-label="jméno"
                    onChange={e => setName(e.target.value)}
                  />
                  <span className="contact__placeholder">Jméno</span>
                </div>
                <div className="contact__input">
                  <input
                    value={email}
                    type="email"
                    name="name"
                    required
                    aria-label="email"
                    onChange={e => setEmail(e.target.value)}
                  />
                  <span className="contact__placeholder">E-mail</span>
                </div>
                <div className="contact__input">
                  <textarea
                    value={message}
                    name="message"
                    required
                    aria-label="vaše zpráva"
                    rows={3}
                    onChange={e => setMessage(e.target.value)}
                  />
                  <span className="contact__placeholder">Vaše zpráva</span>
                </div>
                <Button
                  disabled={
                    !name ||
                    !email ||
                    emailValid !== EmailValidation.Valid ||
                    !message ||
                    awaitResponse
                  }
                  className="btn--outline btn--lg px-5"
                  onClick={handleSubmit}
                  type='submit'
                >
                  Odeslat
                </Button>
              </div>
            </div>
          </div>
        </Tween>
      </Reveal>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey='6Ldl__sUAAAAAN0FqzWhzCTtldtYqWjQV-RAhOHO'
        size='invisible'
      />
    </section>
  );
};
