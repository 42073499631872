import * as React from 'react';

import { MissionImageMain } from './MissionImageMain';
import { MissionImageSide } from './MissionImageSide';
import './Mission.scss';

import logo from '../../assets/images/logo-2023-md.svg';
import { MissionImageGraphic } from './MissionImageGraphic';
import { MutableRefObject } from 'react';
import { Reveal, Tween } from 'react-gsap';

interface Props {
  reference: MutableRefObject<null>
}

export const Mission = ({ reference }: Props) => {
  return (
    <div ref={reference} id="about">
      <Reveal threshold={0}>
        <Tween from={{ opacity: 0, x: -200 }}>
          <section className="mission">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <MissionImageMain className="mission__image-main" />
                  {/*<MissionImageSide className="mission__image-side" />*/}
                  <MissionImageGraphic className="mission__image-graphic" />
                </div>
                <div className="mission__column-left col-md-6">
                  <img className="mb-2" src={logo} alt="Iconic" />
                  <p className="mission__text mb-4">
                    Iconic s.r.o. je marketingová, servisní, produkční,
                    tvůrčí, pořadatelská, mediální, vydavatelská společnost
                    a agentura založená Taťánou Kuchařovou.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </Tween>
      </Reveal>
    </div>
  );
};
